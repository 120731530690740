<template>
  <!--微应用加载器-->
  <div id="microApp"/>
</template>

<script>
import { start } from 'qiankun'
import ExcludeAssets from '../../micro-exclude-assets'

export default {
  created () {
  },
  mounted () {
    start({
      sandbox: {
        strictStyleIsolation: false,
        experimentalStyleIsolation: false
      },
      excludeAssetFilter (assetUrl) {
        for (let i = 0; i < ExcludeAssets.length; i++) {
          const asset = ExcludeAssets[i]
          if (typeof asset === 'function') {
            if (asset(assetUrl)) { return true }
          }
          if (typeof asset === 'string') {
            if (assetUrl === asset) { return true }
          }
        }
      }
      // fetch (url, ...arg) {
      //   console.log(url)
      //   return window.fetch(url, {
      //     headers: {
      //       qiankun: 'yes'
      //     }
      //   })
      // }
    })
  }
}
</script>

/**
 * 子应用引入的第三方脚本会存在跨域问题，需要在此文件中将第三方脚本排除出去(主应用不拦截以下静态文件)
 */

export default [
  // Lodop 打印
  'http://localhost:8000/CLodopfuncs.js?priority=1',
  'http://localhost:18000/CLodopfuncs.js?priority=2',
  'https://localhost:8443/CLodopfuncs.js?priority=0',
  // 高德地图
  (assetUrl) => {
    // 高德主域名下的地址全部排除
    return assetUrl.indexOf('.amap.com') > -1
  },
  // 二期项目kindeditor编辑器
  (assetUrl) => {
    return assetUrl.indexOf('library/kindeditor/4.1.10') > -1
  },
  // 搜狐IP地址查询
  (assetUrl) => {
    return assetUrl.indexOf('pv.sohu.com/cityjson') > -1
  },
  // 易理货CDN静态资源
  (assetUrl) => {
    return assetUrl.indexOf('static.yilihuo.com/ylh/library/') > -1
  },
  // 巨商汇CDN静态资源
  (assetUrl) => {
    return assetUrl.indexOf('jsh-oss-static.jsh.com/static') > -1
  }
]
